import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"

const SmileAgainProgram = () => {
  return (
    <Layout>
      <SEO
        title="Smile Again Program"
        description="It is time for a brand new, healthy, beautiful smile at Canyon Oral & Facial Surgery Dental Implant Experts of Las Vegas."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image"
            alt="smile again banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="https://res.cloudinary.com/nuvolum/image/upload/q_99,f_auto/v1/Programs/smile-again-hero-image-mobile"
            alt="smile again banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>An Opportunity to Smile Again</h1>

            <p>
              Canyon Oral & Facial Surgery is proud to announce its fifth Smile
              Again program. This life-changing program provides a deserving
              recipient from the Las Vegas area with a complete smile makeover
              free of charge.
            </p>

            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a free{" "}
              <Link
                to="/oral-surgery-procedures/full-arch-restoration-all-on-4-las-vegas-nv/"
                title="Learn more about All-on-4®">
                All-on-4<sup>®</sup>
              </Link>{" "}
              treatment concept procedure. We encourage anyone in our community
              who is dreaming of a healthy, beautiful smile to apply!
            </p>

            <h3>
              All-on-4<sup>®</sup> Treatment Concept
            </h3>

            <p>
              An entire smile can be restored in a single procedure. With the
              All-on-4 treatment concept, a customized prosthesis is secured
              onto the dental implant posts on the upper or lower arch. With the
              All-on-4 treatment concept, patients are able to eat, speak, and
              smile with confidence again!
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by September 26, 2023.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              <b>
                Thank you for your interest in the Smile Again program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </b>
            </p>
            <br />
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/Canyonofs.CentennialHills/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/canyonofs/"
                title="Follow us on Instagram"
                target="_blank">
                Instagram
              </a>{" "}
              for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/oral-surgery-procedures/dental-implants-las-vegas-nv/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/oral-surgery-procedures/full-arch-restoration-all-on-4-las-vegas-nv/"
                  title="Learn more about All-on-4 treatment concept">
                  All-on-4 treatment concept
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SmileAgainProgram
